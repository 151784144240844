<template>
    <v-container fluid class="statistique-fl-bloquantes">
      <v-row v-if="dataLoaded"  align="center">
        <v-col cols="12" sm="6">
          <v-card>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Statistiques du jour</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" sm="4">
                  <v-card class="stat-card" color="red accent-4" dark>
                    <v-card-text class="text-center">
                      <div class="text-h1 mb-2 white--text">{{ todayStats.nb_fl_anomalie_bloquante }}</div>
                      <div class="text-h7 white--text text-uppercase">Bloquantes</div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card class="stat-card" color="warning" dark>
                    <v-card-text class="text-center">
                      <div class="text-h1 mb-2 white--text">{{ todayStats.nb_fl_anomalie_non_bloquante }}</div>
                      <div class="text-h7 white--text text-uppercase">Non bloquantes</div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card class="stat-card" color="primary" dark>
                    <v-card-text class="text-center">
                      <div class="text-h1 mb-2 white--text">{{ todayStats.nb_fl_anomalie }}</div>
                      <div class="text-h7 white--text text-uppercase">Total</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
  
        <v-col cols="12" sm="6">
          <v-card>
            <v-toolbar color="secondary" dark>
              <v-toolbar-title>Statistiques globales</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" sm="4">
                  <v-card class="stat-card" color="red accent-4" dark>
                    <v-card-text class="text-center">
                      <div class="text-h1 mb-2 white--text">{{ fullStats.nb_fl_anomalie_bloquante }}</div>
                      <div class="text-h7 white--text text-uppercase">Bloquantes</div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card class="stat-card" color="warning" dark>
                    <v-card-text class="text-center">
                      <div class="text-h1 mb-2 white--text">{{ fullStats.nb_fl_anomalie_non_bloquante }}</div>
                      <div class="text-h7 white--text text-uppercase">Non bloquantes</div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card class="stat-card" color="primary" dark>
                    <v-card-text class="text-center">
                      <div class="text-h1 mb-2 white--text">{{ fullStats.nb_fl_anomalie }}</div>
                      <div class="text-h7 white--text text-uppercase">Total</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        
        <v-col cols="12" class="mt-4">
          <v-card>
            <v-card-text class="d-flex justify-center align-center">
              <v-chip :color="chipColor" large>
                <span class="text-h7">Dernière mise à jour : {{ lastUpdateTime }}</span>
              </v-chip>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else justify="center" align="center" class="fill-height">
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="primary" size="128"></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import MessagerieService from '../../../../Services/MessagerieService';
  import moment from 'moment';
  
  export default {
    data() {
      return {
        todayStats: {
          nb_fl_anomalie_bloquante: 0,
          nb_fl_anomalie: 0,
          nb_fl_anomalie_non_bloquante: 0,
        },
        fullStats: {
          nb_fl_anomalie_bloquante: 0,
          nb_fl_anomalie: 0,
          nb_fl_anomalie_non_bloquante: 0,
        },
        dataLoaded: false,
        lastUpdateTime: '',
        updateInterval: null,
      }
    },
    computed: {
      chipColor() {
        return 'primary';
      }
    },
    mounted() {
      this.fetchData();
      this.startAutoUpdate();
    },
    beforeDestroy() {
      this.stopAutoUpdate();
    },
    methods: {
      async fetchData() {
        try {
          const data = await MessagerieService.getFichesInfoFlBloquante();
          if (data && data.today && data.full) {
            this.todayStats = {
              nb_fl_anomalie_bloquante: data.today.nb_fl_anomalie_bloquante,
              nb_fl_anomalie: data.today.nb_fl_anomalie,
              nb_fl_anomalie_non_bloquante: data.today.nb_fl_anomalie - data.today.nb_fl_anomalie_bloquante,
            };
            this.fullStats = {
              nb_fl_anomalie_bloquante: data.full.nb_fl_anomalie_bloquante,
              nb_fl_anomalie: data.full.nb_fl_anomalie,
              nb_fl_anomalie_non_bloquante: data.full.nb_fl_anomalie - data.full.nb_fl_anomalie_bloquante,
            };
            this.updateLastUpdateTime();
          } else {
            console.error('Structure de données invalide reçue de l\'API');
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
        } finally {
          this.dataLoaded = true;
        }
      },
      startAutoUpdate() {
        this.updateInterval = setInterval(() => {
          this.fetchData();
        }, 60 * 1000); // Rafraîchissement toutes les minutes
      },
      stopAutoUpdate() {
        if (this.updateInterval) {
          clearInterval(this.updateInterval);
        }
      },
      updateLastUpdateTime() {
        this.lastUpdateTime = moment().format('HH:mm');
      }
    }
  }
  </script>
  
  <style scoped>
  .statistique-fl-bloquantes {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
  .stat-card {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .v-card {
    border-radius: 12px;
  }
  .white--text {
    color: white !important;
  }
  </style>